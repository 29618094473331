import { render, staticRenderFns } from "./ReportsBusinessTalks.vue?vue&type=template&id=db1aa56e&scoped=true&"
import script from "./ReportsBusinessTalks.vue?vue&type=script&lang=js&"
export * from "./ReportsBusinessTalks.vue?vue&type=script&lang=js&"
import style0 from "./ReportsBusinessTalks.vue?vue&type=style&index=0&id=db1aa56e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db1aa56e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCol,VList,VListItem,VListItemTitle,VMenu,VRow,VSimpleTable,VTextField})
