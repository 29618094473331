<template>
  <div>
    <!-- <p class="text-center p-0 m-0">Wybierz raport</p> -->
    <template v-if="!selectReportType || !reportTypeFromQuery">
      <v-row
        class="mb-12"
        v-for="reportType in reportTypes"
        :key="reportType.id"
      >
        <v-col class="text-center m-0 p-0 mt-4">
          <yellow-button
            :text="reportType.name"
            @click.native="selectReportType(reportType)"
          />
        </v-col>
      </v-row>
    </template>
    <v-row v-if="reportTypeFromQuery && selectedReportType" class="mt-4">
      <v-col>
        <p class="font-weight-bold text-center report-title">
          {{ selectedReportType.name }}
        </p>
      </v-col>
    </v-row>
    <div
      v-if="
        reportTypeFromQuery &&
        selectedReportType &&
        selectedReportType.id === 'BUSINESS_TALKS'
      "
    >
      <reports-business-talks />
    </div>
  </div>
</template>


<script>
import YellowButton from "../components/buttons/YellowButton.vue";
import ReportsBusinessTalks from "./ReportsBusinessTalks.vue";
export default {
  components: { YellowButton, ReportsBusinessTalks },
  name: "Reports",
  data: function () {
    return {
      reportTypes: [
        {
          id: "BUSINESS_TALKS",
          name: "Raport - ROZMOWY BIZNESOWE",
        },
      ],
      selectedReportType: null,
    };
  },
  methods: {
    selectReportType: function (reportType) {
      this.selectedReportType = reportType;
      this.$router.push({
        name: this.$router.currentRoute.name,
        query: {
          ...this.$router.currentRoute.query,
          reportType: reportType.id,
        },
      });
    },
  },
  computed: {
    reportTypeFromQuery: function () {
      return this.$route.query.reportType;
    },
  },
};
</script>

<style scoped>
.report-title {
  font-size: 16px;
  font-weight: bold;
}
</style>
