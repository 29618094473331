var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"0 auto","padding":"2rem 0"}},[_c('v-card',{staticClass:"px-5",attrs:{"elevation":"0","tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"time-picker"},[_c('div',{staticClass:"inputLabel"},[_vm._v("OD")]),_c('v-text-field',{staticClass:"time p-0 m-0 mt-0 pt-1",attrs:{"single-line":"","type":"month","color":"#fbc501"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"time-picker"},[_c('div',{staticClass:"inputLabel"},[_vm._v("DO")]),_c('v-text-field',{staticClass:"time p-0 m-0 mt-0 pt-1",attrs:{"single-line":"","type":"month","color":"#fbc501"},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)])],1)],1),(_vm.contacts && _vm.contacts.length > 0)?_c('v-simple-table',{attrs:{"dense":""}},[[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Temat")]),_c('th',{staticClass:"text-left",staticStyle:{"padding-left":"0"}},[_vm._v("Data")]),_c('th',{staticClass:"text-left"},[_vm._v("Odbył się")]),_c('th',{staticClass:"text-left"}),_c('th',{staticClass:"text-left"})])]),_c('tbody',_vm._l((_vm.contacts),function(item){return _c('tr',{key:item.id,style:({
            backgroundColor: item.done
              ? '#fff6d9'
              : item.notDone
              ? 'lightgray'
              : '#f6f6f6',
          })},[_c('td',{staticStyle:{"min-width":"140px"}},[_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(item.customer.fullname)+" ")]),_vm._v(" "+_vm._s(item.topic)+" ")]),_c('td',{staticStyle:{"padding-left":"0","min-width":"80px"}},[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.date))+" "+_vm._s(item.dateTo ? (" - " + (item.dateTo.slice(0, 10) === item.date.slice(0, 10) ? _vm.getFormattedDate(item.dateTo).slice(11) : _vm.getFormattedDate(item.dateTo))) : "")+" ")]),_c('td',{staticClass:"business-conversation-row"},[_c('div',[(_vm.isDateAfterNowAndNoActionMade(item))?_c('font-awesome-icon',{attrs:{"icon":"question-circle","color":"#fbc501"}}):(item.done)?_c('span',[_vm._v("tak")]):(item.notDone)?_c('span',[_vm._v("nie")]):_c('font-awesome-icon',{attrs:{"icon":"question-circle","color":"#fbc501"}})],1),_c('div',[(item.businessConversation)?_c('div',{staticClass:"business-conversations"},[_vm._v(" "+_vm._s(_vm.extractTextInParentheses(item.businessConversation))+" ")]):_vm._e()])]),_c('td',{staticStyle:{"padding":"0px","width":"20%","text-align":"center"}},[(item.done !== true && item.notDone !== true)?_c('font-awesome-icon',{staticClass:"ml-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"red","icon":"times"},on:{"click":function($event){return _vm.markContactAsNotDone(item)}}}):_vm._e(),(item.done !== true && item.notDone !== true)?_c('font-awesome-icon',{staticClass:"ml-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"green","icon":"check"},on:{"click":function($event){return _vm.markContactAsDone(item)}}}):_vm._e(),_c('font-awesome-icon',{staticClass:"ml-3",staticStyle:{"cursor":"pointer"},attrs:{"color":"#3f51b5","icon":"edit"},on:{"click":function($event){return _vm.editContact(item)}}})],1),_c('td',{staticClass:"text-center",staticStyle:{"padding":"0px 5px 0px 0px","width":"10%"}},[_c('v-menu',{attrs:{"nudge-bottom":"20","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('font-awesome-icon',_vm._g(_vm._b({staticClass:"mx-3",staticStyle:{"cursor":"pointer"},attrs:{"icon":"ellipsis-v"}},'font-awesome-icon',attrs,false),on))]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.editContact(item)}}},[_c('v-list-item-title',[_vm._v("Edytuj")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.editContact(item)}}},[_c('v-list-item-title',[_vm._v("Podgląd")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteContact(item)}}},[_c('v-list-item-title',[_vm._v("Usuń")])],1)],1)],1)],1)])}),0)]],2):_vm._e(),(_vm.contacts && _vm.contacts.length === 0)?_c('div',{staticClass:"text-center bold"},[_vm._v(" Brak kontaktów ")]):_vm._e(),_c('set-date-time-from-to-modal',{attrs:{"isVisible":_vm.contactModal,"customerId":_vm.selectedContact && _vm.selectedContact.customer
        ? _vm.selectedContact.customer.id
        : '',"datetimeFrom":_vm.selectedDateTimeFrom,"datetimeTo":_vm.selectedDateTimeTo,"contact":_vm.selectedContact},on:{"closeModal":function($event){return _vm.closeSetDateTimeModal()}}}),_c('confirm-delete-modal',{attrs:{"isVisible":_vm.confirmDeleteModal,"contact":_vm.selectedContact},on:{"closeModal":_vm.closeConfirmDeleteModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }