import axios from "../../axios";
import store from "../../store";
import errorHandler from "../../errorHandler";
import moment from "moment";

const customers = {
  namespaced: true,

  state: () => ({
    customers: "",
    customer: "",
    customerCategories: [],
    contacts: [],
    allContacts: [],
    customersPrimaryData: [],
    businessConversationsContacts: [],
  }),

  mutations: {
    SET_CUSTOMERS(state, customers) {
      state.customers = customers;
    },
    SET_CONTACTS(state, contacts) {
      state.contacts = contacts;
    },
    SET_CUSTOMER(state, customer) {
      state.customer = customer;
    },
    SET_CUSTOMER_CATEGORIES(state, categories) {
      state.customerCategories = categories;
    },
    SET_BUSINESS_CONVERSATIONS_CONTACTS(state, contacts) {
      state.businessConversationsContacts = contacts;
    },
    SET_ALL_CONTACTS(state, contacts) {
      state.allContacts = contacts;
    },
    SET_CUSTOMERS_PRIMARY_DATA(state, data) {
      state.customersPrimaryData = data;
    },
  },

  actions: {
    async fetchCustomersPrimaryData({ commit }) {
      const url = `customer/primaryData`;
      try {
        const response = await axios.get(url);
        commit("SET_CUSTOMERS_PRIMARY_DATA", response.data);
      } catch (error) {
        console.log(error.response);
        commit("SET_CUSTOMERS_PRIMARY_DATA", []);
      }
    },
    async fetchCustomers({ dispatch, commit }, object) {
      let { limit, sorting, filter, deleted } = object;
      const showDeleted = deleted ? "deleted=true&" : "";
      const _limit = limit ? `limit=${limit}&` : "";
      const sortBy =
        sorting?.sortBy0 && sorting?.sortBy1
          ? `sortBy[0]=${object.sorting.sortBy0}&sortBy[1]=${object.sorting.sortBy1}&`
          : "";

      const filterByCategory =
        sorting && sorting.category === ""
          ? "withoutCategories=true&"
          : sorting && sorting.category
          ? `category=${sorting.category}&`
          : "";

      const filterByCategoryLevel2 =
        sorting && sorting.categoryLevel2 === ""
          ? "withoutCategoriesLevel2=true&"
          : sorting && sorting.categoryLevel2
          ? `categoryLevel2=${sorting.categoryLevel2}&`
          : "";

      const _filter = filter ? `search=${filter}&` : "";

      const baseUrl = "customer?";
      let url =
        baseUrl +
        _limit +
        sortBy +
        filterByCategory +
        filterByCategoryLevel2 +
        _filter +
        showDeleted;
      url = url.slice(0, -1);
      console.log(url);
      try {
        const response = await axios.get(url);
        commit("SET_CUSTOMERS", response.data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async setCustomerToNull({ commit }) {
      commit("SET_CUSTOMER", {});
    },
    async fetchCustomer({ dispatch, commit }, object) {
      try {
        const response = await axios.get(`customer/${object.id}`);
        commit("SET_CUSTOMER", response.data);
      } catch (error) {
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async fetchCustomerCategories({ commit }) {
      try {
        const response = await axios.get(`customer-categories`);
        commit("SET_CUSTOMER_CATEGORIES", response.data);
      } catch (error) {
        console.log(error.response);
      }
    },
    async addCustomer({ dispatch, commit }, object) {
      try {
        const response = await axios.post(`customer`, object);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        commit("SET_CUSTOMER", response.data);
        dispatch("fetchCustomers", {});
        return response.data;
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async editCustomer({ dispatch }, object) {
      try {
        const updatedCustomer = await axios.put(
          `customer/${object.id}`,
          object.data
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        dispatch("fetchCustomer", object);
        return updatedCustomer.data;
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addCategories({ dispatch }, object) {
      try {
        await axios.post(`customer/${object.customerId}/customer-categories`, {
          categoriesIds: object.categoriesIds,
        });
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano kategorię!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        let data = {
          id: object.customerId,
        };
        dispatch("fetchCustomer", data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async removeCategory({ dispatch }, object) {
      try {
        await axios.delete(
          `customer/${object.customerId}/customer-category/${object.categoryId}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto kategorię!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        let data = {
          id: object.customerId,
        };
        dispatch("fetchCustomer", data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async deleteCustomer({ dispatch }, customerId) {
      try {
        await axios.delete(`customer/${customerId}`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async addContact({ dispatch }, object) {
      const convertedDateFrom = moment(object.contactDateFrom)
        .utcOffset("+0:00")
        .toDate();
      const convertedDateTo = moment(object.contactDateTo)
        .utcOffset("+0:00")
        .toDate();
      let date = {
        date: convertedDateFrom,
        dateTo: convertedDateTo,
        done: object.contactMade,
        topic: object.topic,
        notDone: object.contactNotMade,
        businessConversation: object.businessConversation,
      };
      try {
        await axios.post(`customer/${object.customerId}/contact`, date);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie dodano kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        let data = {
          id: object.customerId,
        };
        dispatch("fetchCustomer", data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async markContactAsDone({ dispatch }, object) {
      try {
        await axios.put(
          `customer/${object.customerId}/contact/${object.id}/mark-as-done`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kontak został oznaczony jako odbyty!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        store.dispatch("user/checkContactsForToday");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async markContactAsNotDone({ dispatch }, object) {
      try {
        await axios.put(
          `customer/${object.customerId}/contact/${object.id}/mark-as-not-done`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kontak został oznaczony jako nieodbyty!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        store.dispatch("user/checkContactsForToday");
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async fetchContacts({ dispatch, commit }, object) {
      try {
        const response = await axios.get(
          `customer/${object.id}/contact?limit=${object.limit}${
            object.withoutSortingOnDone ? "&withoutSortingOnDone=true" : ""
          }`
        );
        commit("SET_CONTACTS", response.data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async deleteContact({ dispatch }, object) {
      try {
        await axios.delete(
          `customer/${object.customerId}/contact/${object.id}`
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie usunięto kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },
    async softDeleteCustomer({ dispatch }, customerId) {
      try {
        await axios.delete(`customer/${customerId}/softDelete`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kontakt został przeniosiony do kosza!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async restoreCustomer({ dispatch }, customerId) {
      try {
        await axios.post(`customer/${customerId}/restoreDeleted`);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Kontakt został przywrócony!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async editContact({ dispatch }, object) {
      let date = {
        date: object.contactDateFrom,
        dateTo: object.contactDateTo,
        done: object.contactMade,
        topic: object.topic,
        notDone: object.contactNotMade,
        businessConversation: object.businessConversation,
      };
      try {
        await axios.put(
          `customer/${object.customerId}/contact/${object.contactId}`,
          date
        );
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "success",
            icon: "check",
            message: "Pomyślnie edytowano kontakt!",
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
        let data = {
          id: object.customerId,
        };
        dispatch("fetchCustomer", data);
      } catch (error) {
        console.log(error.response);
        dispatch(
          "snackbar/setSnackbar",
          {
            color: "red",
            icon: "exclamation-triangle",
            message: errorHandler(
              error.response.data.message,
              error.response.data.code
            ),
          },
          { root: true }
        );
        dispatch("snackbar/toggleSnackbar", true, { root: true });
      }
    },

    async updateCustomerPhoto({ commit }, data) {
      try {
        const { file, customerId } = data;
        const formData = new FormData();
        formData.append("file", file);
        const url = `customer/${customerId}/photo`;
        const response = await axios.put(url, formData);
        commit("SET_CUSTOMERS", response.data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchAllContacts({ commit }) {
      try {
        const response = await axios.get(`customer/allContacts`);
        commit("SET_ALL_CONTACTS", response.data);
      } catch (error) {
        console.log(error.response);
      }
    },
    async fetchBusinessConversationsContacts({ commit }, object) {
      try {
        const params = {
          dateFrom: object.dateFrom,
          dateTo: object.dateTo,
        };
        const response = await axios.get(`customer/businessContacts`, {
          params,
        });
        commit("SET_BUSINESS_CONVERSATIONS_CONTACTS", response.data);
      } catch (error) {
        console.log(error.response);
      }
    },

    resetBusinessConversationsContacts({ commit }) {
      commit("SET_BUSINESS_CONVERSATIONS_CONTACTS", []);
    },
  },

  getters: {
    getCustomers: (state) => state.customers,
    getContacts: (state) => state.contacts,
    getCustomer: (state) => state.customer,
    getCustomerCategories: (state) => state.customerCategories,
    getAllContacts: (state) => state.allContacts,
    getCustomersPrimaryData: (state) => state.customersPrimaryData,
    getBusinessConversationsContacts: (state) =>
      state.businessConversationsContacts,
  },
};
export default customers;
