<template>
  <div style="margin: 0 auto; padding: 2rem 0">
    <v-card elevation="0" tile class="px-5">
      <v-row>
        <v-col cols="6">
          <div class="time-picker">
            <div class="inputLabel">OD</div>
            <v-text-field
              class="time p-0 m-0 mt-0 pt-1"
              v-model="dateFrom"
              single-line
              type="month"
              color="#fbc501"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="time-picker">
            <div class="inputLabel">DO</div>
            <v-text-field
              class="time p-0 m-0 mt-0 pt-1"
              v-model="dateTo"
              single-line
              type="month"
              color="#fbc501"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-simple-table dense v-if="contacts && contacts.length > 0">
      <template>
        <thead>
          <tr>
            <th class="text-left">Temat</th>
            <th class="text-left" style="padding-left: 0">Data</th>
            <th class="text-left">Odbył się</th>
            <th class="text-left"></th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in contacts"
            :key="item.id"
            :style="{
              backgroundColor: item.done
                ? '#fff6d9'
                : item.notDone
                ? 'lightgray'
                : '#f6f6f6',
            }"
          >
            <td style="min-width: 140px">
              <p class="font-weight-bold mb-0">
                {{ item.customer.fullname }}
              </p>
              {{ item.topic }}
            </td>
            <td style="padding-left: 0; min-width: 80px">
              {{ getFormattedDate(item.date) }}
              {{
                item.dateTo
                  ? ` - ${
                      item.dateTo.slice(0, 10) === item.date.slice(0, 10)
                        ? getFormattedDate(item.dateTo).slice(11)
                        : getFormattedDate(item.dateTo)
                    }`
                  : ""
              }}
            </td>
            <td class="business-conversation-row">
              <div>
                <font-awesome-icon
                  v-if="isDateAfterNowAndNoActionMade(item)"
                  icon="question-circle"
                  color="#fbc501"
                />
                <span v-else-if="item.done">tak</span>
                <span v-else-if="item.notDone">nie</span>
                <font-awesome-icon
                  v-else
                  icon="question-circle"
                  color="#fbc501"
                />
              </div>
              <div>
                <div
                  v-if="item.businessConversation"
                  class="business-conversations"
                >
                  {{ extractTextInParentheses(item.businessConversation) }}
                </div>
              </div>
            </td>
            <td style="padding: 0px; width: 20%; text-align: center">
              <font-awesome-icon
                style="cursor: pointer"
                color="red"
                icon="times"
                class="ml-3"
                v-if="item.done !== true && item.notDone !== true"
                @click="markContactAsNotDone(item)"
              />
              <font-awesome-icon
                style="cursor: pointer"
                color="green"
                icon="check"
                class="ml-3"
                @click="markContactAsDone(item)"
                v-if="item.done !== true && item.notDone !== true"
              />
              <font-awesome-icon
                style="cursor: pointer"
                color="#3f51b5"
                icon="edit"
                class="ml-3"
                @click="editContact(item)"
              />
            </td>
            <td
              class="text-center"
              style="padding: 0px 5px 0px 0px; width: 10%"
            >
              <v-menu nudge-bottom="20" bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <font-awesome-icon
                    icon="ellipsis-v"
                    style="cursor: pointer"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-3"
                  />
                </template>
                <v-list dense>
                  <v-list-item @click="editContact(item)">
                    <v-list-item-title>Edytuj</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="editContact(item)">
                    <v-list-item-title>Podgląd</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteContact(item)">
                    <v-list-item-title>Usuń</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div class="text-center bold" v-if="contacts && contacts.length === 0">
      Brak kontaktów
    </div>

    <set-date-time-from-to-modal
      :isVisible="contactModal"
      @closeModal="closeSetDateTimeModal()"
      :customerId="
        selectedContact && selectedContact.customer
          ? selectedContact.customer.id
          : ''
      "
      :datetimeFrom="selectedDateTimeFrom"
      :datetimeTo="selectedDateTimeTo"
      :contact="selectedContact"
    />
    <confirm-delete-modal
      :isVisible="confirmDeleteModal"
      @closeModal="closeConfirmDeleteModal"
      :contact="selectedContact"
    />
  </div>
</template>


<script>
import moment from "moment";
import shared from "../shared";
import SetDateTimeFromToModal from "../components/modals/SetDateTimeFromToModal.vue";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal.vue";
export default {
  components: { SetDateTimeFromToModal, ConfirmDeleteModal },
  name: "ReportsBusinessTalks",
  data: function () {
    return {
      selectedContact: null,
      selectedDateTimeFrom: null,
      selectedDateTimeTo: null,
      contactModal: false,
      confirmDeleteModal: false,
      dateFrom: null,
      dateTo: null,
    };
  },
  async created() {
    this.dateFrom = moment().startOf("month").format("YYYY-MM");
    this.dateTo = moment().endOf("month").format("YYYY-MM");
    await this.fetchContacts();
  },
  destroyed() {
    this.$store.dispatch("customers/resetBusinessConversationsContacts", []);
  },
  methods: {
    async fetchContacts() {
      await this.$store.dispatch(
        "customers/fetchBusinessConversationsContacts",
        {
          dateFrom: moment(`${this.dateFrom}-01`)
            .startOf("month")
            .format(`YYYY-MM-DD`),
          dateTo: moment(`${this.dateTo}-01`)
            .endOf("month")
            .format(`YYYY-MM-DD`),
        }
      );
    },
    getFormattedDate(data) {
      return shared.formattedDateTime(data);
    },

    isDateAfterNowAndNoActionMade(contact) {
      return (
        moment().isAfter(moment(contact.date)) &&
        !contact.done &&
        !contact.notDone
      );
    },
    extractTextInParentheses(text) {
      const result = text.match(/\((.*?)\)/);
      return result ? result[1] : null;
    },
    async markContactAsNotDone(item) {
      let object = {
        customerId: item.customerId,
        id: item.id,
      };
      let customer = {
        id: item.customerId,
      };
      await this.$store.dispatch("customers/markContactAsNotDone", object);
      await this.$store.dispatch("customers/fetchCustomer", customer);
      await this.fetchContacts();
    },
    async markContactAsDone(item) {
      let object = {
        customerId: item.customerId,
        id: item.id,
      };
      let customer = {
        id: item.customerId,
      };
      await this.$store.dispatch("customers/markContactAsDone", object);
      await this.$store.dispatch("customers/fetchCustomer", customer);
      await this.fetchContacts();
    },

    editContact(item) {
      this.selectedContact = item;
      this.selectedDateTimeFrom = item.date;
      this.selectedDateTimeTo = item.dateTo;
      this.contactModal = true;
    },

    async closeSetDateTimeModal() {
      this.contactModal = false;
      this.selectedDateTimeFrom = null;
      this.selectedDateTimeTo = null;
      this.selectedContact = null;

      await this.fetchContacts();
    },
    async deleteContact(item) {
      this.selectedContact = item;
      this.confirmDeleteModal = true;
    },
    async closeConfirmDeleteModal(deleted) {
      this.selectedContact = "";
      this.confirmDeleteModal = false;
      if (deleted) {
        await this.fetchContacts();
      }
    },
  },
  watch: {
    dateFrom: function () {
      if (!this.dateFrom) {
        this.dateFrom = moment().startOf("month").format("YYYY-MM");
      }
      this.fetchContacts();
    },
    dateTo: function () {
      if (!this.dateTo) {
        this.dateTo = moment().endOf("month").format("YYYY-MM");
      }
      this.fetchContacts();
    },
  },
  computed: {
    contacts() {
      return this.$store.getters["customers/getBusinessConversationsContacts"];
    },
  },
};
</script>

<style scoped>
td {
  font-size: 11px !important;
}
.business-conversation-row {
  padding-right: 0px;
  min-height: 40px;
  text-align: left;
}
.business-conversations {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 1px;
  width: fit-content;
  margin-bottom: 5px;
}
@media screen and (max-width: 600px) {
  .time-picker {
    text-align: center;
  }
}
</style>
