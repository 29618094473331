<template>
  <v-row justify="center" dense>
    <v-col cols="12" sm="11" md="9" lg="7" xl="6">
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-card-actions>
          <Localisation office officeType="KARTA kontaktu" />
        </v-card-actions>
        <!-- <v-card-text class="pb-1 text-right">FBO: #312312 </v-card-text> -->
      </v-card>
      <v-card
        tile
        elevation="0"
        class="d-flex align-center justify-space-between"
      >
        <v-card-title class="pt-0" style="display: block">
          <div class="name-info">
            <div>
              <customer-image-preview
                :imagePath="customer.imagePath"
                :sizeInPx="100"
              />
            </div>
            <div class="personal-info">
              <p>{{ customer.fullname }}</p>
            </div>
          </div>
          <font-awesome-icon
            icon="edit"
            style="cursor: pointer"
            color="black"
            @click="editCustomer()"
            class="ml-10"
          />
        </v-card-title>
      </v-card>
      <v-card tile elevation="0">
        <v-card-actions style="font-size: 0.8rem" class="py-0 black--text">
          <v-card-actions class="yellow-block">
            <div style="font-size: 0.8rem">Numer FBO</div>
            <YellowButton
              :text="customer.fbo ? customer.fbo : '-'"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
              :minWidth="true"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card-text style="font-size: 0.8rem" class="py-0 black--text">
          <p
            class="note-format"
            v-html="customer.note ? customer.note : 'Brak notatki o kontakcie'"
          ></p>
        </v-card-text>
        <v-card-actions style="flex-wrap: wrap">
          <v-card-actions class="yellow-block">
            <div style="font-size: 0.8rem">Numer telefonu</div>
            <YellowButton
              v-clipboard="customer.phone ? customer.phone : ''"
              @success="customer.phone ? handlePhoneClick() : null"
              :text="customer.phone ? customer.phone : '-'"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
              :minWidth="true"
            />
          </v-card-actions>
          <v-card-actions class="yellow-block">
            <div style="font-size: 0.8rem">Adres e-mail</div>
            <YellowButton
              v-clipboard="customer.email ? customer.email : ''"
              @success="customer.email ? handleEmailCopy() : null"
              :text="customer.email ? customer.email : '-'"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              :minWidth="true"
              width="100%"
              :maxWidthAndWrap="true"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card-actions>
          <v-card-actions
            class="yellow-block"
            style="width: fit-content; max-width: 100%"
          >
            <div style="font-size: 0.8rem">Adres</div>
            <YellowButton
              v-clipboard="
                customer.address && customer.address !== ''
                  ? customer.address
                  : '-'
              "
              :minWidth="true"
              @success="
                customer.address && customer.address !== ''
                  ? handleAddressCopy()
                  : null
              "
              :text="
                customer.address && customer.address !== ''
                  ? customer.address
                  : '-'
              "
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
              :wrap="true"
              height="fit-content"
              style="
                min-height: fit-content;
                min-height: fit-content;
                white-space: pre;
                margin-right: 1rem;
                margin-left: 1rem;
              "
              :maxWidthAndWrap="true"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card-actions>
          <v-card-actions class="yellow-block" style="width: 33%">
            <div style="font-size: 0.8rem">Data urodzin</div>
            <YellowButton
              :text="
                customer.birthDateDay && customer.birthDateMonth
                  ? formatCustomerBirthDate()
                  : '-'
              "
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
              :minWidth="true"
            />
          </v-card-actions>

          <v-card-actions class="yellow-block" style="width: 33%">
            <div style="font-size: 0.8rem">Wiek</div>
            <YellowButton
              :text="
                customer.birthDateDay &&
                customer.birthDateMonth &&
                customer.birthDateYear
                  ? getCustomerAge()
                  : '-'
              "
              truncate
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
              :minWidth="true"
            />
          </v-card-actions>

          <v-card-actions class="yellow-block" style="width: 33%">
            <div style="font-size: 0.8rem">Data imienin</div>
            <YellowButton
              :text="
                customer.nameDayDay && customer.nameDayMonth
                  ? formatCustomerNameDayDate()
                  : '-'
              "
              truncate
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              width="100%"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card-actions>
          <v-card-actions
            v-if="
              customer.customers_social_links &&
              customer.customers_social_links.length > 0
            "
            class="yellow-block"
            style="width: 100%"
          >
            <div style="font-size: 0.8rem" class="py-0 black--text">
              Linki do social mediów/stron www
            </div>

            <YellowButton
              v-for="socialLink in customer.customers_social_links"
              :key="socialLink.id"
              width="100%"
              :text="socialLink.link"
              @click.native="moveToLink(socialLink.link)"
              style="margin: 0; margin-bottom: 0.5rem"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
              :minWidth="true"
            />
          </v-card-actions>
          <v-card-actions v-else class="yellow-block" style="width: 100%">
            <div style="font-size: 0.8rem" class="py-0 black--text">
              Linki do social mediów/stron www
            </div>

            <YellowButton
              width="100%"
              :text="'-'"
              style="margin: 0; margin-bottom: 0.5rem"
              :xsmall="$vuetify.breakpoint.xs"
              :small="$vuetify.breakpoint.smAndUp"
            />
          </v-card-actions>
        </v-card-actions>

        <v-card tile elevation="0">
          <v-card-text class="py-0 pl-2 black--text">Kategoria</v-card-text>
          <v-card-actions class="py-0 d-flex justify-space-between">
            <div class="py-0">
              <span
                v-if="
                  customerCategoriesLevel1 &&
                  customerCategoriesLevel1.length != 0
                "
                class="d-flex flex-wrap"
                style="width: 100%"
              >
                <span
                  v-for="category in customerCategoriesLevel1"
                  :key="category.id"
                >
                  <span class="black--text">{{ category.name }} </span>
                  <font-awesome-icon
                    class="fa-sm mr-4"
                    color="grey"
                    @click="removeCategory(category.id)"
                    icon="times"
                    style="cursor: pointer"
                  />
                </span>
              </span>
              <span v-else class="bold">Brak kategorii</span>
              <span
                v-if="
                  customerCategoriesLevel1 &&
                  customerCategoriesLevel1.length > 0 &&
                  customerCategoriesLevel2 &&
                  customerCategoriesLevel2.length != 0
                "
                class="d-flex flex-wrap subcategory mt-4"
                style="width: 100%"
              >
                <span
                  v-for="category in customerCategoriesLevel2"
                  :key="category.id"
                >
                  <span class="black--text">{{ category.name }} </span>
                  <font-awesome-icon
                    class="fa-sm mr-4"
                    color="grey"
                    @click="removeCategory(category.id)"
                    icon="times"
                    style="cursor: pointer"
                  />
                </span>
              </span>
            </div>

            <WhiteButton
              class="ml-3 align-self-start"
              border
              icon="plus"
              @click.native="addCategory()"
              small
              text="Dodaj kat."
            />
          </v-card-actions>
        </v-card>
      </v-card>
      <v-card elevation="0" tile>
        <v-card-actions class="pt-0 mt-2">
          <v-card-text class="headline pa-0 font-weight-bold"
            >KALKULACJE</v-card-text
          >
          <WhiteButton
            border
            icon="plus"
            @click.native="
              $router.push(`/calculator?customerId=${customer.id}`)
            "
            small
            text="Dodaj ofertę"
          />
        </v-card-actions>
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">Nr oferty</th>
                <th class="text-left">Cena</th>
                <th class="text-left">CC:</th>
                <th class="text-left">Status</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in calculations"
                :key="item.id"
                :class="{ tableColor: index % 2 != 0 }"
              >
                <td>
                  <span
                    style="cursor: pointer"
                    @click="$router.push(`/calculation/${item.id}`)"
                    >{{ item.identifier }}
                  </span>
                </td>
                <td>{{ item.totalValue }}</td>
                <td>{{ item.ccTotalValue }}</td>
                <td>
                  <span
                    :class="{
                      blueText: item.calculation_status.name === 'ORDERED',
                      yellowText:
                        item.calculation_status.name === 'PARTIALLY_REALIZED' ||
                        item.calculation_status.name === 'REALIZED_UNPAID',
                      greenText:
                        item.calculation_status.name === 'REALIZED_PAID',
                      redText: item.calculation_status.name === 'NOT_REALIZED',
                    }"
                    >{{ getStatus(item.calculation_status.name) }}</span
                  >
                </td>
                <td>
                  <v-menu nudge-bottom="20" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        icon="ellipsis-v"
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-list dense>
                      <v-list-item
                        @click="$router.push(`/calculator?id=${item.id}`)"
                      >
                        <v-list-item-title>Edytuj</v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="$router.push(`/calculation-offer/${item.id}`)"
                      >
                        <v-list-item-title>Podgląd</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          class="text-center bold"
          v-if="
            customer &&
            customer.calculations &&
            customer.calculations.length === 0
          "
        >
          Brak ofert w historii
        </div>
        <v-card-actions class="justify-center"
          ><YellowButton
            text="Pokaż więcej"
            v-if="
              customer &&
              customer.calculations &&
              customer.calculations.length > 5 &&
              calculations.length != customer.calculations.length
            "
            small
            @click.native="showMoreCalculations()"
        /></v-card-actions>
      </v-card>
      <v-card-actions class="justify-space-between black--text">
        <div style="width: 45%">
          <div>Ostatni Kontakt</div>
          <div style="font-size: 0.8rem" v-if="customer.lastContactDate">
            {{ getLastContact(customer.lastContactDate) }}
          </div>
          <div v-else>Brak</div>
        </div>
      </v-card-actions>
      <v-card elevation="0" tile class="mb-8">
        <v-card-actions class="pt-0 my-2">
          <v-card-text class="headline pa-0 font-weight-bold"
            >KALENDARZ</v-card-text
          >
          <div>
            <YellowButton
              icon="calendar-alt"
              xsmall
              text="Zaplanuj datę"
              @click.native="contactModal = true"
            />
          </div>
        </v-card-actions>
        <v-simple-table dense>
          <template>
            <thead>
              <tr>
                <th class="text-left">Temat</th>
                <th class="text-left">Data</th>
                <th class="text-left" style="padding-right: 0px">Odbył się</th>
                <th class="text-left"></th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in contacts.data"
                :key="item.id"
                :class="{ tableColor: index % 2 != 0 }"
              >
                <td>{{ item.topic }}</td>
                <td class="date-row">
                  {{ getFormattedDate(item.date) }}
                  {{
                    item.dateTo
                      ? ` - ${
                          item.dateTo.slice(0, 10) === item.date.slice(0, 10)
                            ? getFormattedDate(item.dateTo).slice(11)
                            : getFormattedDate(item.dateTo)
                        }`
                      : ""
                  }}
                </td>
                <td class="business-conversation-row">
                  <div>
                    <font-awesome-icon
                      v-if="isDateAfterNowAndNoActionMade(item)"
                      icon="question-circle"
                      color="#fbc501"
                    />
                    <span v-else-if="item.done">tak</span>
                    <span v-else-if="item.notDone">nie</span>
                    <font-awesome-icon
                      v-else
                      icon="question-circle"
                      color="#fbc501"
                    />
                  </div>
                  <div>
                    <div
                      v-if="item.businessConversation"
                      class="business-conversations"
                    >
                      {{ extractTextInParentheses(item.businessConversation) }}
                    </div>
                  </div>
                </td>
                <td style="padding: 0px; width: 20%; text-align: center">
                  <font-awesome-icon
                    style="cursor: pointer"
                    color="red"
                    icon="times"
                    class="ml-3"
                    v-if="item.done !== true && item.notDone !== true"
                    @click="markContactAsNotDone(item)"
                  />
                  <font-awesome-icon
                    style="cursor: pointer"
                    color="green"
                    icon="check"
                    class="ml-3"
                    @click="markContactAsDone(item)"
                    v-if="item.done !== true && item.notDone !== true"
                  />
                  <font-awesome-icon
                    style="cursor: pointer"
                    color="#3f51b5"
                    icon="edit"
                    class="ml-3"
                    @click="editContact(item)"
                  />
                </td>
                <td
                  class="text-center"
                  style="padding: 0px 5px 0px 0px; width: 10%"
                >
                  <v-menu nudge-bottom="20" bottom left>
                    <template v-slot:activator="{ on, attrs }">
                      <font-awesome-icon
                        icon="ellipsis-v"
                        style="cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                        class="mx-3"
                      />
                    </template>
                    <v-list dense>
                      <v-list-item @click="editContact(item)">
                        <v-list-item-title>Edytuj</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="editContact(item)">
                        <v-list-item-title>Podgląd</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteContact(item)">
                        <v-list-item-title>Usuń</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div
          class="text-center bold"
          v-if="contacts && contacts.data && contacts.data.length === 0"
        >
          Brak kontaktów w historii
        </div>
        <v-card-actions class="justify-center"
          ><YellowButton
            text="Pokaż więcej"
            v-if="
              contacts &&
              contacts.count > 5 &&
              contacts.data &&
              contacts.data.length < contacts.count
            "
            small
            @click.native="showMoreContacts()"
            :minWidth="true"
        /></v-card-actions>
      </v-card>

      <CustomerModal
        :isEdit="isEdit"
        :isVisible="editModal"
        @closeModal="closeModal()"
        @fetch="fetchCustomer"
      />
      <CustomerCategoriesModal
        :isVisible="categoryModal"
        @closeModal="categoryModal = false"
      />
      <SetDateTimeFromToModal
        :isVisible="contactModal"
        @closeModal="closeSetDateTimeModal()"
        :customerId="customer.id"
        :datetimeFrom="selectedDateTimeFrom"
        :datetimeTo="selectedDateTimeTo"
        :contact="selectedContact"
      />
      <ConfirmDeleteModal
        :isVisible="confirmDeleteModal"
        @closeModal="closeConfirmDeleteModal"
        :contact="selectedContact"
      />
    </v-col>
  </v-row>
</template>

<script>
import YellowButton from "../components/buttons/YellowButton";
import CustomerModal from "../components/modals/CustomerModal";
import Localisation from "../components/Localisation";
import SetDateTimeFromToModal from "../components/modals/SetDateTimeFromToModal";
import ConfirmDeleteModal from "../components/modals/ConfirmDeleteModal";
import CustomerCategoriesModal from "../components/modals/CustomerCategoriesModal";
import WhiteButton from "../components/buttons/WhiteButton";
import { dateCalcDays } from "../utils/dateFormatting";
import shared from "../shared";
import CustomerImagePreview from "../components/CustomerImagePreview.vue";
import moment from "moment";
export default {
  name: "CustomerPage",
  components: {
    YellowButton,
    WhiteButton,
    Localisation,
    CustomerModal,
    SetDateTimeFromToModal,
    CustomerCategoriesModal,
    ConfirmDeleteModal,
    CustomerImagePreview,
  },

  data: () => ({
    tab: "",
    limit: 5,
    contactsLimit: 5,
    selectedTab: "calculations",
    editModal: false,
    contactModal: false,
    confirmDeleteModal: false,
    categoryModal: false,
    isEdit: false,
    calculations: [],
    selectedContact: "",
    selectedDateTimeFrom: null,
    selectedDateTimeTo: null,
  }),

  methods: {
    handlePhoneClick() {
      window.open(`tel:${this.customer.phone}`, "_self");
    },
    extractTextInParentheses(text) {
      const result = text.match(/\((.*?)\)/);
      return result ? result[1] : null;
    },
    getCustomerAge() {
      let { birthDateDay, birthDateMonth, birthDateYear } = this.customer;
      if (birthDateDay && birthDateMonth && birthDateYear) {
        return moment(
          `${birthDateDay}-${birthDateMonth}-${birthDateYear}`,
          "DD-MM-YYYY"
        )
          .fromNow(true)
          .replace(/\D/g, "");
      }
    },
    formatCustomerBirthDate() {
      let { birthDateDay, birthDateMonth, birthDateYear } = this.customer;
      if (birthDateDay && birthDateMonth && birthDateYear) {
        return moment(
          `${birthDateDay}-${birthDateMonth}-${birthDateYear}`,
          "DD-MM-YYYY"
        ).format("DD.MM.YYYY");
      } else if (birthDateDay && birthDateMonth) {
        return moment(
          `${birthDateDay}-${birthDateMonth}-1990`,
          "DD-MM-YYYY"
        ).format("DD.MM");
      }
    },
    formatCustomerNameDayDate() {
      let { nameDayDay, nameDayMonth } = this.customer;
      if (nameDayDay && nameDayMonth) {
        return moment(
          `${nameDayDay}-${nameDayMonth}-1990`,
          "DD-MM-YYYY"
        ).format("DD.MM");
      }
    },

    isDateAfterNowAndNoActionMade(contact) {
      return (
        moment().isAfter(moment(contact.date)) &&
        !contact.done &&
        !contact.notDone
      );
    },
    async fetchCustomer() {
      await this.$store.dispatch("customers/fetchCustomer", {
        id: this.$route.params.customerId,
      });
    },

    moveToLink(link) {
      window.open(link, "_blank");
    },
    showMoreCalculations() {
      this.limit += 5;
      this.calculations = JSON.parse(
        JSON.stringify(this.customer.calculations.slice(0, this.limit))
      );
    },
    async showMoreContacts() {
      this.contactsLimit += 5;
      let object = {
        id: this.$route.params.customerId,
        limit: this.contactsLimit,
        withoutSortingOnDone: true,
      };
      await this.$store.dispatch("customers/fetchContacts", object);
    },
    getStatus(data) {
      return shared.formatRole(data);
    },
    getFormattedDate(data) {
      return shared.formattedDateTime(data);
    },
    getLastContact(date) {
      return dateCalcDays(date);
    },
    handleEmailCopy() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Skopiowano adres email do schowka!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    handleAddressCopy() {
      this.$store.dispatch(
        "snackbar/setSnackbar",
        {
          color: "success",
          icon: "check",
          message: "Skopiowano adres do schowka!",
        },
        { root: true }
      );
      this.$store.dispatch("snackbar/toggleSnackbar", true, { root: true });
    },
    removeCategory(categoryId) {
      let object = {
        customerId: this.$route.params.customerId,
        categoryId: categoryId,
      };
      this.$store.dispatch("customers/removeCategory", object);
    },
    async editCustomer() {
      let object = {
        id: this.$route.params.customerId,
      };
      await this.$store.dispatch("customers/fetchCustomer", object);
      this.isEdit = true;
      this.editModal = true;
      setTimeout(() => {
        document.getElementById("customer-modal-title").scrollIntoView();
      }, 100);
    },
    async addCategory() {
      await this.$store.dispatch("customers/fetchCustomerCategories");
      this.categoryModal = true;
    },
    closeModal() {
      this.isEdit = false;
      this.editModal = false;
    },
    async closeSetDateTimeModal() {
      this.contactModal = false;
      this.selectedDateTimeFrom = null;
      this.selectedDateTimeTo = null;
      this.selectedContact = null;

      await this.fetchContacts();
    },
    async fetchContacts() {
      let object = {
        id: this.$route.params.customerId,
        limit: this.contactsLimit,
        withoutSortingOnDone: true,
      };
      await this.$store.dispatch("customers/fetchContacts", object);
    },
    async deleteContact(item) {
      this.selectedContact = item;
      this.confirmDeleteModal = true;
    },
    async closeConfirmDeleteModal(deleted) {
      this.selectedContact = "";
      this.confirmDeleteModal = false;
      if (deleted) {
        await this.fetchContacts();
      }
    },
    async markContactAsDone(item) {
      let object = {
        customerId: item.customerId,
        id: item.id,
      };
      let customer = {
        id: item.customerId,
      };
      await this.$store.dispatch("customers/markContactAsDone", object);
      await this.$store.dispatch("customers/fetchCustomer", customer);
      await this.fetchContacts();
    },

    async markContactAsNotDone(item) {
      let object = {
        customerId: item.customerId,
        id: item.id,
      };
      let customer = {
        id: item.customerId,
      };
      await this.$store.dispatch("customers/markContactAsNotDone", object);
      await this.$store.dispatch("customers/fetchCustomer", customer);
      await this.fetchContacts();
    },

    editContact(item) {
      this.selectedContact = item;
      this.selectedDateTimeFrom = item.date;
      this.selectedDateTimeTo = item.dateTo;
      this.contactModal = true;
    },
  },

  created() {
    let object1 = {
      id: this.$route.params.customerId,
    };
    let object2 = {
      id: this.$route.params.customerId,
      limit: this.contactsLimit,
      withoutSortingOnDone: true,
    };
    this.$store.dispatch("customers/fetchCustomer", object1);
    this.$store.dispatch("customers/fetchContacts", object2);
  },
  computed: {
    customer() {
      return this.$store.getters["customers/getCustomer"];
    },
    contacts() {
      return this.$store.getters["customers/getContacts"];
    },
    user() {
      return this.$store.getters["user/getUser"];
    },
    customerCategoriesLevel1() {
      if (
        this.customer &&
        this.customer.customer_categories &&
        this.customer.customer_categories.length > 0
      ) {
        return this.customer.customer_categories.filter(
          (c) => !c.level || c.level === 1
        );
      } else {
        return [];
      }
    },
    customerCategoriesLevel2() {
      if (
        this.customer &&
        this.customer.customer_categories &&
        this.customer.customer_categories.length > 0
      ) {
        return this.customer.customer_categories.filter((c) => c.level === 2);
      } else {
        return [];
      }
    },
  },
  watch: {
    customer() {
      this.calculations = JSON.parse(
        JSON.stringify(
          this.customer.calculations.reverse().slice(0, this.limit)
        )
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.date-row {
  font-size: 0.85rem !important;
}
.customer-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fbo-number {
  font-size: 0.4em;
}
.selectedTab {
  border-top: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}
.unselectedTab {
  border-bottom: 1px solid grey;
}
.selectedTabItem {
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-left: 1px solid grey;
}
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  .v-data-table__wrapper
  table
  tbody
  tr:not(:last-child)
  th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
@import "~vuetify/src/styles/settings/_variables";

.category {
  word-wrap: none;
}
.break {
  word-break: break-word;
}
a {
  color: black !important;
}
.subcategory {
  font-size: 11px;
}
.name-info {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.personal-info p {
  margin: 0;
  padding: 0;
  font-size: 18px;
  word-break: normal;
  overflow-wrap: break-word;
}
.business-conversation-row {
  padding-right: 0px;
  min-height: 40px;
  text-align: left;
}
.business-conversations {
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 1px;
  width: fit-content;
  margin-bottom: 5px;
}
.yellow-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.note-format {
  white-space: pre-wrap;
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin-top: 20px;
  width: 100%;
  border-radius: 5px;
  width: fit-content;
}
</style>
