<template>
  <div class="dots">
    <v-menu nudge-bottom="20" bottom left>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <font-awesome-icon
            icon="ellipsis-v"
            style="cursor: pointer"
            class="mr-3"
            color="black"
          />
        </div>
      </template>
      <v-list dense>
        <v-list-item @click="editContact(contact)">
          <v-list-item-title>Edytuj</v-list-item-title>
        </v-list-item>
        <v-list-item @click="editContact(contact)">
          <v-list-item-title>Podgląd</v-list-item-title>
        </v-list-item>
        <v-list-item @click="deleteContact(contact)">
          <v-list-item-title>Usuń</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <set-date-time-from-to-modal
      :isVisible="contactModal"
      @closeModal="closeSetDateTimeModal()"
      :datetimeFrom="selectedDateTimeFrom"
      :datetimeTo="selectedDateTimeTo"
      :contact="selectedContact"
      :customerId="selectedContact ? selectedContact.customerId : ''"
      @contactsUpdated="handleContactsUpdated"
    />
    <ConfirmDeleteModal
      :isVisible="confirmDeleteModal"
      @closeModal="closeConfirmDeleteModal"
      :contact="selectedContact"
    />
  </div>
</template>
<script>
import ConfirmDeleteModal from "./modals/ConfirmDeleteModal.vue";
import SetDateTimeFromToModal from "./modals/SetDateTimeFromToModal.vue";
export default {
  components: { SetDateTimeFromToModal, ConfirmDeleteModal },
  emits: ["contactsUpdated"],
  props: {
    contact: {
      type: Object,
      required: true,
    },
  },
  name: "CustomerContactDots",
  data() {
    return {
      contactModal: false,
      selectedDateTimeTo: "",
      selectedDateTimeFrom: "",
      selectedContact: null,
      confirmDeleteModal: false,
    };
  },
  methods: {
    async deleteContact(item) {
      this.selectedContact = item;
      this.confirmDeleteModal = true;
    },
    async closeConfirmDeleteModal(deleted) {
      this.selectedContact = "";
      this.confirmDeleteModal = false;
      if (deleted) {
        this.handleContactsUpdated();
      }
    },

    editContact(contact) {
      this.selectedContact = contact;
      this.selectedDateTimeFrom = contact.date;
      this.selectedDateTimeTo = contact.dateTo;
      this.contactModal = true;
    },

    async closeSetDateTimeModal() {
      this.contactModal = false;
      this.selectedDateTimeFrom = null;
      this.selectedDateTimeTo = null;
      this.selectedContact = null;
    },
    handleContactsUpdated() {
      this.$emit("contactsUpdated", {
        customer: this.selectedContact.customerId,
      });
    },
  },
};
</script>

<style scoped>
.dots {
  z-index: 10000000;
}
</style>
